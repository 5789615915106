/* eslint-disable jsx-a11y/anchor-is-valid */
import { CloseOutlined, DownOutlined, MenuOutlined, ReadFilled } from '@ant-design/icons';
import { SearchOutlined } from '@ant-design/icons';
import { Dropdown, Input, Menu } from 'antd';
import Spinner from 'components/shared/spinner';
import { gql } from 'graphql-request';
import Image from 'next/image';
import Link from 'next/link';
import { useRouter } from 'next/router';
import loginIcon from 'public/images/icons/log-in-outline.svg';
import profileIcon from 'public/images/icons/profile.svg';
import { useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import styled from 'styled-components';
import main from 'utils/mutationWrapper';

import { InvertedRoundButton, RoundButton, SuccessRoundButton } from '@/design-system/buttons';

import { TeamInvitationNotice } from './TeamInvitationNotice';

const { Search } = Input;
const { SubMenu, Item } = Menu;
const Logo = styled(Image)`
  height: 40px;
  width: auto;
  @media (min-width: 550px) and (max-width: 1024px) {
    height: 30px;
    width: auto;
  }
  @media (min-width: 320px) and (max-width: 550px) {
    height: 25px;
    width: auto;
  }
`;

const StyledMenu = styled(Menu)`
  width: 268px;
  background: ${({ theme }) => theme.cardBackground};
  border-radius: 2px;
  border: none;
  li.ant-menu-submenu.ant-menu-submenu-vertical,
  li.ant-menu-submenu.ant-menu-submenu-vertical.ant-menu-submenu-active {
    font-weight: 600;
    font-size: 16px;
    line-height: 32px;
    color: ${({ theme }) => theme.fadedGrey};
    background: ${({ theme }) => theme.menuBg};
  }
  div.ant-menu-submenu-title {
    display: flex;
    justify-content: space-between;
    padding: 0 8px;
  }
  div.ant-menu-item-active,
  div.ant-menu-item:hover,
  div.ant-menu-submenu-active,
  div.ant-menu-submenu-title:hover,
  div.ant-menu-submenu-title:active,
  div.ant-menu:not(.ant-menu-inline) .ant-menu-submenu-open {
    color: ${({ theme }) => theme.fadedGrey};
    background: rgba(255, 255, 255, 0.05);
  }
  li.ant-menu-submenu-vertical > .ant-menu-submenu-title:hover .ant-menu-submenu-arrow:before,
  li.ant-menu-submenu-vertical > .ant-menu-submenu-title:hover .ant-menu-submenu-arrow:after,
  li.ant-menu-submenu-vertical > .ant-menu-submenu-title .ant-menu-submenu-arrow:before,
  li.ant-menu-submenu-vertical > .ant-menu-submenu-title .ant-menu-submenu-arrow:after {
    background: ${({ theme }) => theme.fadedGrey};
  }
`;
const MobileMenu = styled.button`
  background: transparent;
  margin-bottom: -5px;
  margin-left: 5px;
  @media (min-width: 1025px) {
    display: none;
  }
  @media (max-width: 1025px) {
    display: block;
    overflow-y: scroll;
  }
  .icon {
    font-size: 30px;
  }
`;
const MobileSubMenu = styled.div`
  background: ${(props) => props.theme.headerBackground};
  padding: 0 18px 16px 18px;
  width: 80%;
  right: 0;
  top: 80px;
  position: absolute;
  .mobile-category {
    height: 65px;
    width: 100%;
    display: flex;
    align-items: center;
    padding-left: 16px;
    font-weight: bold;
    font-size: 15px;
    line-height: 18px;
    letter-spacing: 0.03em;
    text-transform: uppercase;
    color: ${(props) => props.theme.primaryText};
    background: ${(props) => props.theme.menuBlack};
  }
  .mobile-category a {
    color: ${(props) => props.theme.primaryText};
  }
  .tab-menu-footer {
    height: 65px;
    width: 100%;
    background-color: ${(props) => props.theme.menuHoverBlack};
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 50px;
  }
  .tab-menu-auth {
    display: grid;
    height: 100%;
    align-items: center;
    grid-template-columns: 1fr 1fr;
  }
  .mobile-menu-footer {
    min-height: 65px;
    width: 100%;
    background-color: ${(props) => props.theme.menuHoverBlack};
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 15px;
    .hide-on-mobile {
      @media (max-width: 550px) {
        display: none;
      }
    }
  }
  .mobile-menu-div {
    height: 105px;
    width: 100%;
    background-color: ${(props) => props.theme.menuHoverBlack};
  }
  .mobile-signup-div {
    display: grid;
    place-items: center;
    padding-bottom: 10px;
  }
  @media (min-width: 1025px) {
    display: none;
  }
  @media (max-width: 1025px) {
    display: block;
    overflow-y: scroll;
  }
  @media (min-width: 320px) and (max-width: 550px) {
    //phone
    .tab-menu-footer {
      display: none;
    }
    .mobile-menu-div {
      display: block;
    }
  }

  @media (min-width: 550px) and (max-width: 1025px) {
    //tabs
    .tab-menu-footer {
      display: flex;
    }
    .mobile-menu-div {
      display: none;
    }
  }
`;

const Learn = styled.div`
  height: 35px;
  width: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${(props) => props.theme.anotherText};
  background: ${(props) => props.theme.primaryText};
  font-size: 14px;
  line-height: 17px;
  font-weight: bold;
`;
const Library = styled.button`
  width: 100px;
  background: transparent;
  color: ${(props) => props.theme.primaryText};
  letter-spacing: 0.02em;
  font-size: 14px;
  line-height: 17px;
  font-weight: bold;
  border: none;
  display: flex;
  @media (max-width: 1024px) {
    display: none;
  }
`;

const StyledHeader = styled.header`
  width: 100%;
  height: 80px;
  left: 0px;
  top: 0px;
  right: 0px;
  position: fixed;
  display: inline-grid;
  grid-template-columns: 1fr 1fr;
  padding: 0 40px;
  align-items: center;
  background: ${(props) => props.theme.headerBackground};
  z-index: 10000;
  box-shadow: 0px 5px 23px rgba(0, 0, 0, 0.19);
  .hide-on-mobile {
    @media (max-width: 550px) {
      display: none;
    }
  }
  .hide-on-tab {
    @media (max-width: 1024px) {
      display: none;
    }
  }
  .search-sign-section {
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
    display: grid;
    grid-template-columns: 3fr 1fr 1fr;
    grid-column-gap: 10px;
    .hide-on-mobile {
      @media (max-width: 550px) {
        display: none;
      }
    }
    .hide-on-tab {
      @media (max-width: 1024px) {
        display: none;
      }
    }
    .show-on-mobile {
      @media (min-width: 550px) {
        display: none;
      }
      @media (max-width: 550px) {
        display: flex;
        color: #ffffff;
        font-size: 2.5rem;
        justify-content: center;
      }
    }
    .show-on-tab {
      @media (min-width: 1024px) {
        display: none;
      }
      @media (max-width: 1024px) {
        display: flex;
        color: #ffffff;
        font-size: 2.5rem;
        justify-content: center;
      }
    }
  }
  .avatar {
    width: 40px !important;
    min-width: 40px !important;
    height: 40px !important;
    border-radius: 50%;
    justify-self: flex-end;
    cursor: pointer;
  }
  @media (min-width: 550px) and (max-width: 1024px) {
    grid-template-columns: 1fr 2fr;
    padding: 0 35px;
  }
  @media (min-width: 320px) and (max-width: 550px) {
    grid-template-columns: 1fr 2fr;
    padding: 0 16px;
  }
  @media (max-width: 1024px) {
    .signup-desktop {
      display: none;
    }
    .learn-desktop {
      display: none;
    }
    .signin-desktop {
      display: none;
    }
    .avatar {
      display: none;
    }
    .search-sign-section {
      grid-template-columns: 3fr 1fr;
      &.regroup-mobile {
        @media (max-width: 1024px) {
          grid-template-columns: 1fr 1fr 1fr;
        }
      }
      &.regroup-mobile-no-auth {
        @media (max-width: 1024px) {
          grid-template-columns: 1fr 1fr;
        }
      }
    }
  }
  .logo-menu-section {
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
    padding-right: 100px;

    @media (min-width: 320px) and (max-width: 550px) {
      //phone
      padding-right: 10px;
    }
  }
  .learn-icon {
    color: ${(props) => props.theme.primaryLink};
    margin-right: 5px;
  }
  .drop-icon {
    margin-left: 8px;
    font-weight: bold;
    letter-spacing: 0.02em;
    font-size: 14px !important;
    line-height: 17px;
  }
  .search {
    width: 100%;
    flex: 2;
  }
  .signin {
    flex: 1;
    font-weight: bold;
    font-size: 14px;
    line-height: 17px;
    color: ${(props) => props.theme.primaryText};
    text-align: center;
    margin-right: 19px;
  }
  .sub-bar {
    display: grid;
    grid-template-columns: 1fr auto;
    border-bottom: 1px solid ${(props) => props.theme.lightgrey};
  }

  .notice-bar {
    position: absolute;
    bottom: calc(-25% + -0.3em);
    display: block;
    width: 100%;
  }
`;

const StyledAvatarSubMenu = styled.div`
  width: 220px;
  background-color: ${(props) => props.theme.headerBackground};
  padding: 10px;
  box-shadow: 0px 20px 40px rgba(0, 0, 0, 0.4);
  backdrop-filter: blur(40px);
  border-radius: 0px 0px 10px 10px;
  .avatar-pic-name {
    display: grid;
    place-items: center;
    margin: 40px 0;
  }
  .avatar-pic {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    margin-bottom: 15px;
    object-fit: cover;
  }
  .avatar-name {
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    letter-spacing: 0.05em;
    color: ${(props) => props.theme.primaryText};
  }
  a.avatar-menu-item {
    color: ${(props) => props.theme.upgradeCon};
    margin-bottom: 10px;
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 18px;
    letter-spacing: 0.03em;
    display: block;
  }
  a.avatar-menu-item > img {
    margin-right: 10px;
  }
  .divider {
    height: 1px;
    opacity: 0.4;
    border: 1px solid ${({ theme }) => theme.borderGreen};
    margin-bottom: 10px;
  }
`;

const CATEGORIES_QUERY = gql`
  query categories($input: QueryCategoryInput!) {
    categories(input: $input) {
      itemsList {
        _id
        url
        name
        courses {
          _id
          url
          name
        }
        topics {
          _id
          url
          name
          imageUrl
        }
      }
    }
  }
`;

const Header = (props) => {
  // eslint-disable-next-line no-unused-vars
  const [_, setCookie] = useCookies([]);
  const router = useRouter();

  const { menuOpen, setMenuOpen, auth } = props;
  const activeSubscription = auth?.currentSubscription ? auth?.currentSubscription : null;
  const [categories, setCategories] = useState([]);
  const [status, setStatus] = useState('LOADING');
  const [searchValue, setSearchValue] = useState(router.query.q);

  useEffect(() => {
    setSearchValue(router.query.q);
  }, [router.query.q]);

  useEffect(() => {
    fetchCategories();
  }, []);

  const fetchCategories = async () => {
    setStatus('LOADING');
    try {
      const { categories: apiCategories } = await main(CATEGORIES_QUERY, {
        input: {
          page: 0,
          limit: 10
        }
      });
      const { itemsList } = apiCategories;
      setCategories(itemsList);
      setStatus('DONE');
    } catch (err) {
      console.log(err);
      setStatus('DONE');
    }
  };

  const logOut = () => {
    setCookie('activeUser', '', {
      path: '/',
      expires: new Date('Thu, 01 Jan 1970 00:00:00 GMT')
    });
    setCookie('email', '', {
      path: '/',
      expires: new Date('Thu, 01 Jan 1970 00:00:00 GMT')
    });
    setCookie('token', '', {
      path: '/',
      expires: new Date('Thu, 01 Jan 1970 00:00:00 GMT')
    });
    setCookie('isLoggedIn', '', {
      path: '/',
      expires: new Date('Thu, 01 Jan 1970 00:00:00 GMT')
    });
    setCookie('loggedInUser', '', {
      path: '/',
      expires: new Date('Thu, 01 Jan 1970 00:00:00 GMT')
    });

    global.analytics.track('User Signed Out');

    location.replace('/');
  };

  const avatarMenu = (authUser) => (
    <StyledAvatarSubMenu>
      <div className="avatar-pic-name">
        <Image
          width={100}
          height={100}
          className="avatar-pic"
          src={authUser?.imageUrl || '/images/avatar.jpg'}
          alt="Avatar"
        />
        <div className="avatar-name">
          {authUser.firstName &&
            authUser.firstName.replace(/^./, authUser.firstName[0].toUpperCase())}{' '}
          {authUser.lastName && authUser.lastName.replace(/^./, authUser.lastName[0].toUpperCase())}
        </div>
      </div>
      <Link href="/profile">
        <a className="avatar-menu-item">
          <Image src={profileIcon} alt="Profile icon" />
          Profile
        </a>
      </Link>
      <Link href="/billing">
        <a className="avatar-menu-item">
          <Image src={profileIcon} alt="Profile icon" />
          Billing
        </a>
      </Link>
      {authUser?.role?.toLowerCase() === 'author' && (
        <Link href="/dashboard/instructor">
          <a className="avatar-menu-item">
            <Image src={profileIcon} alt="Profile icon" />
            Instructor Dashboard
          </a>
        </Link>
      )}
      {auth?.currentSubscription?.subscription?.is_team &&
        auth?.currentSubscription?.subscription?.userId == auth._id && (
          <Link href="/team/dashboard">
            <a className="avatar-menu-item">
              <Image src={profileIcon} alt="Profile icon" />
              Manage Team
            </a>
          </Link>
        )}
      <div className="divider" />
      {/* <Link href="/teach">
        <a className="avatar-menu-item">
          <img src="/images/icons/profile.svg" />
          Teach on Brytahub
        </a>
      </Link>
      <Link href="/refer">
        <a className="avatar-menu-item">
          <img src="/images/icons/profile.svg" />
          Refer a friend
        </a>
      </Link>
      <div className="divider" /> */}
      <Link passHref href="">
        <div>
          <a
            className="avatar-menu-item"
            onClick={() => {
              logOut();
            }}>
            <Image src={loginIcon} alt="Outline" />
            Signout
          </a>
        </div>
      </Link>
    </StyledAvatarSubMenu>
  );

  const goToCategory = (catUrl) => () => {
    router.push(`/categories/${catUrl}`);
  };

  const vocMenu = (cats) => (
    <>
      <StyledMenu>
        {cats.map((cat) => (
          <SubMenu title={cat.name} key={cat._id} onTitleClick={goToCategory(cat.url)}>
            {cat.topics.map((topic) => (
              <Item key={topic._id}>
                <Link href={`/topics/${topic.url}`}>{topic.name}</Link>
              </Item>
            ))}
          </SubMenu>
        ))}
      </StyledMenu>
    </>
  );

  return (
    <StyledHeader>
      <div className="logo-menu-section">
        <Link passHref href="/">
          <a aria-label="Brytahub homepage">
            <Logo width={180} height={40} src="/images/logo.png" alt="Brytahub logo" />
          </a>
        </Link>

        <Dropdown overlay={vocMenu(categories)} trigger="click">
          <Library>
            Categories
            <DownOutlined className="drop-icon" />
          </Library>
        </Dropdown>
        {/* <Link passHref href="/skill-paths">
          <a className="learn-desktop">
            <Learn>
              <ReadFilled className="learn-icon" />
              Paths
            </Learn>
          </a>
        </Link> */}
        <Link passHref href="/blog">
          <a className="blog-link hide-on-mobile hide-on-tab">
            <Learn>Blog</Learn>
          </a>
        </Link>
      </div>
      <div
        className={`search-sign-section ${!auth ? 'regroup-mobile-no-auth' : 'regroup-mobile'} `}>
        <Search
          value={searchValue}
          placeholder="What do you want to learn?"
          onSearch={(value) => router.push(`/search?q=${value}`)}
          className={`search ${!auth ? 'hide-on-mobile' : 'hide-on-tab'}`}
          onChange={({ value }) => setSearchValue(value)}
        />
        <Link href="/search">
          <a className={!auth ? 'show-on-mobile' : 'show-on-tab'}>
            <SearchOutlined />
          </a>
        </Link>
        {!auth ? (
          <>
            <Link passHref href="/signin">
              <a className="signin signin-desktop">Log in</a>
            </Link>
            <Link passHref href="/register">
              <InvertedRoundButton
                className="signup-desktop"
                whileHover={{
                  scale: 1.05,
                  transition: {
                    duration: 0.2
                  }
                }}>
                Register Free
              </InvertedRoundButton>
            </Link>
          </>
        ) : (
          <>
            {!activeSubscription && (
              <Link passHref href="/pricing">
                <SuccessRoundButton className="signup-desktop">Go Pro</SuccessRoundButton>
              </Link>
            )}

            <Dropdown placement="bottomRight" overlay={avatarMenu(auth)} trigger="click">
              <Image
                src={auth?.imageUrl || '/images/avatar.jpg'}
                width={40}
                height={40}
                className="avatar"
                alt={auth.firstName}
              />
            </Dropdown>
          </>
        )}

        {!menuOpen ? (
          <MobileMenu onClick={() => setMenuOpen(true)}>
            <MenuOutlined className="icon" />
          </MobileMenu>
        ) : (
          <MobileMenu onClick={() => setMenuOpen(false)}>
            <CloseOutlined className="icon" />
          </MobileMenu>
        )}
        {menuOpen && (
          <MobileSubMenu>
            {status === 'LOADING' ? (
              <Spinner />
            ) : (
              categories.map((category) => (
                <div className="mobile-category" key={category._id}>
                  <Link passHref href={`/categories/${category.url}`}>
                    <a onClick={() => setMenuOpen(false)}>{category.name}</a>
                  </Link>
                </div>
              ))
            )}
            <div className="tab-menu-footer">
              <Link passHref href="/learn">
                <a>
                  <Learn>
                    <ReadFilled className="learn-icon" />
                    Learn
                  </Learn>
                </a>
              </Link>
              {!auth ? (
                <>
                  <div className="tab-menu-auth">
                    <Link passHref href="/signin">
                      <a className="signin">Log in</a>
                    </Link>
                    <Link passHref href="/register">
                      <RoundButton>Register Free</RoundButton>
                    </Link>
                  </div>
                </>
              ) : (
                <>
                  <div className="tab-menu-auth">
                    <Link passHref href="">
                      <a
                        className="signin"
                        onClick={() => {
                          logOut();
                        }}>
                        Sign out
                      </a>
                    </Link>
                    <Link passHref href="/profile">
                      <a className="signin">Profile</a>
                    </Link>
                  </div>
                  <Dropdown overlay={avatarMenu(auth)} trigger="click">
                    <Image
                      width={40}
                      height={40}
                      src={auth?.imageUrl || '/images/avatar.jpg'}
                      className="avatar"
                      alt={auth.firstName}
                    />
                  </Dropdown>
                </>
              )}
            </div>
            <div className="mobile-menu-div">
              <div className="mobile-menu-footer">
                {/* <Link passHref href="/skill-paths">
                  <a className={!auth ? 'hide-on-mobile' : ''}>
                    <Learn>
                      <ReadFilled className="learn-icon" />
                      Paths
                    </Learn>
                  </a>
                </Link> */}
                <Link passHref href="/blog">
                  <a className={!auth ? 'hide-on-mobile' : ''}>
                    <Learn>
                      <ReadFilled className="learn-icon" />
                      Blog
                    </Learn>
                  </a>
                </Link>
                {!auth ? (
                  <>
                    <div className="tab-menu-auth">
                      <Link passHref href="/signin">
                        <a className="signin">Log in</a>
                      </Link>
                      <Link passHref href="/register">
                        <RoundButton>Register Free</RoundButton>
                      </Link>
                    </div>
                  </>
                ) : (
                  <>
                    <div className="tab-menu-auth">
                      <Link passHref href="">
                        <a
                          className="signin"
                          onClick={() => {
                            logOut();
                          }}>
                          Sign out
                        </a>
                      </Link>
                      <Link passHref href="/profile">
                        <a className="signin">Profile</a>
                      </Link>
                    </div>
                  </>
                )}
              </div>
            </div>
            <div className="mobile-signup-div">
              {!activeSubscription && (
                <Link passHref href="/pricing">
                  <a>
                    <RoundButton>Go Pro</RoundButton>
                  </a>
                </Link>
              )}
            </div>
          </MobileSubMenu>
        )}
      </div>
      <div className="notice-bar">
        <TeamInvitationNotice auth={auth} />
      </div>
    </StyledHeader>
  );
};

export default Header;
