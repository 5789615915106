import Image from 'next/image';
import loaderGif from 'public/images/loader.gif';
import styled from 'styled-components';

const LoaderContainer = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;

  img {
    width: 50px;
    height: 50px;
  }
`;

export default function Loader({ className }) {
  return (
    <LoaderContainer className={className}>
      <Image src={loaderGif} width="50" height="50" alt="Loading..." />
    </LoaderContainer>
  );
}
