import {
  FacebookFilled,
  InstagramFilled,
  LinkedinFilled,
  TwitterSquareFilled
} from '@ant-design/icons';
import { FeedbackFish } from '@feedback-fish/react';
import { Input } from 'antd';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import styled from 'styled-components';

const { Search } = Input;

const StyledFooter = styled.footer`
  .whole {
    bottom: 0;
    width: 100%;
    left: 0;
    right: 0;
  }
  .main {
    height: 412px;
    background: ${(props) => props.theme.testimonialBg};
    color: ${(props) => props.theme.primaryText};
    padding: 60px 40px 40px 40px;
    display: flex;
  }
  .sub {
    height: 80px;
    background: ${(props) => props.theme.newBg};
    padding: 0 40px;
    color: ${(props) => props.theme.primaryText};
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .sub-footer-link {
    flex: 1;
    text-align: center;
    letter-spacing: 0.04em;
    color: ${(props) => props.theme.primaryText};
    font-weight: normal;
    font-size: 14px;
    line-height: 24px;
    opacity: 0.7;
  }
  .copyright {
    flex: 3;
    text-align: right;
    letter-spacing: 0.04em;
    color: ${(props) => props.theme.primaryText};
    font-weight: bold;
    font-size: 14px;
    line-height: 24px;
  }
  .main-section {
    flex: 1;
  }
  .main-section-header {
    color: ${(props) => props.theme.primaryText};
    font-weight: bold;
    font-size: 19px;
    line-height: 23px;
    margin-bottom: 10px;
  }
  .main-section-contents > a,
  .main-section-contents button {
    color: ${(props) => props.theme.primaryText};
    background: transparent;
    border: none;
    font-size: 16px;
    line-height: 25px;
    letter-spacing: 0.04em;
    opacity: 0.7;
    display: block;
    padding: 0;
  }
  .main-search-section {
    flex: 2;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
  }

  .mobile-main-search-section {
    flex: 2;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
  }
  @media (min-width: 320px) and (max-width: 550px) {
    //phone
    .main-search-section {
      display: none;
    }
    .mobile-search-section {
      display: flex;
      margin-top: -100px;
      background: ${(props) => props.theme.cardBackground};
      color: ${(props) => props.theme.primaryText};
      padding: 81px 40px;
      display: flex;
    }
  }

  @media (min-width: 550px) {
    //tabs and higher
    .main-search-section {
      display: flex;
    }
    .mobile-search-section {
      display: none;
    }
  }
  span > span > button {
    background: ${(props) => props.theme.primaryLink} !important;
    height: 58px !important;
    width: 67px;
    border: none;
  }
  button > span > svg {
    font-size: 34px;
  }
  input::placeholder {
    color: ${(props) => props.theme.greyPlaceholder};
    opacity: 0.7;
  }
  input[type='text'] {
    background: ${(props) => props.theme.greyBackground};
    height: 58px;
  }
  .connect {
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
    align-items: center;
    letter-spacing: 0.04em;
    text-transform: uppercase;
    color: ${(props) => props.theme.primaryText};
    opacity: 0.7;
  }
  .divider {
    font-size: 32px;
    padding: 0 6px;
  }
  .social-icon {
    color: ${(props) => props.theme.primaryText};
    opacity: 0.7;
    font-size: 32px;
    margin-left: 5px;
  }
`;

const Footer = ({ auth }) => {
  const router = useRouter();
  const [searchValue, setSearchValue] = useState(router.query.q);

  useEffect(() => {
    setSearchValue(router.query.q);
  }, [router.query.q]);

  return (
    <StyledFooter>
      <div className="whole">
        <div className="main">
          <div className="main-section">
            <div className="main-section-header">CONTENT</div>
            <div className="main-section-contents">
              <Link href="/categories" passHref>
                <a>Categories</a>
              </Link>
              <Link href="/courses" passHref>
                <a>Courses</a>
              </Link>
              <Link href="/lessons" passHref>
                <a>Lessons</a>
              </Link>
              {/* <Link href="/skill-paths" passHref>
                <a>Learning Paths</a>
              </Link> */}
              <Link href="/topics" passHref>
                <a>Topics</a>
              </Link>
            </div>
          </div>
          <div className="main-section">
            <div className="main-section-header">ABOUT US</div>
            <div className="main-section-contents">
              <Link href="/pricing" passHref>
                <a>Pricing</a>
              </Link>
              <Link href="/register" passHref>
                <a>Register Free</a>
              </Link>
              <Link href="/instructors" passHref>
                <a>Instructors</a>
              </Link>
              <Link href="/faq" passHref>
                <a>FAQ</a>
              </Link>
              {process.env.NEXT_PUBLIC_FEEDBACK_FISH_PROJECT_ID && (
                <FeedbackFish
                  projectId={process.env.NEXT_PUBLIC_FEEDBACK_FISH_PROJECT_ID}
                  userId={auth?.email ?? null}>
                  <button>Send feedback</button>
                </FeedbackFish>
              )}
            </div>
          </div>
          <div className="main-search-section">
            <Search
              value={searchValue}
              placeholder="What do you want to learn?"
              enterButton
              size="large"
              className="search"
              onSearch={(value) => router.push(`/search?q=${value}`)}
              onChange={({ value }) => setSearchValue(value)}
            />
            <div>
              <span className="connect">Connect</span>
              <span className="divider">|</span>
              <span>
                <a href="https://twitter.com/brytahub" target="_blank" rel="nofollow noreferrer">
                  <FacebookFilled className="social-icon" />
                </a>
                <a href="https://facebook.com/brytahub" target="_blank" rel="nofollow noreferrer">
                  <TwitterSquareFilled className="social-icon" />
                </a>
                <a href="https://instagram.com/brytahub" target="_blank" rel="nofollow noreferrer">
                  <InstagramFilled className="social-icon" />
                </a>
                <a href="https://linkedin.com/brytahub" target="_blank" rel="nofollow noreferrer">
                  <LinkedinFilled className="social-icon" />
                </a>
              </span>
            </div>
          </div>
        </div>
        <div className="mobile-search-section">
          <div className="mobile-main-search-section">
            <Search
              placeholder="What do you want to learn?"
              enterButton
              size="large"
              className="search"
              onSearch={(value) => console.log(value)}
            />
            <div>
              <span className="connect">Connect</span>
              <span className="divider">|</span>
              <span>
                <a
                  href="https://twitter.com/brytahub"
                  title="Follow Brytahub on Twitter"
                  target="_blank"
                  rel="nofollow noreferrer">
                  <FacebookFilled className="social-icon" />
                </a>
                <a
                  href="https://facebook.com/brytahub"
                  title="Follow Brytahub on Facebook"
                  target="_blank"
                  rel="nofollow noreferrer">
                  <TwitterSquareFilled className="social-icon" />
                </a>
                <a
                  href="https://instagram.com/brytahub"
                  title="Follow Brytahub on Instagram"
                  target="_blank"
                  rel="nofollow noreferrer">
                  <InstagramFilled className="social-icon" />
                </a>
                <a
                  href="https://www.linkedin.com/company/brytahub"
                  title="Follow Brytahub on LinkedIn"
                  target="_blank"
                  rel="nofollow noreferrer">
                  <LinkedinFilled className="social-icon" />
                </a>
              </span>
            </div>
          </div>
        </div>
        <div className="sub">
          <Link href="/privacy" passHref>
            <a className="sub-footer-link">Privacy Policy</a>
          </Link>
          <Link href="/terms" passHref>
            <a className="sub-footer-link">Terms of Use</a>
          </Link>
          <Link href="/" passHref>
            <a className="copyright">&copy; Brytahub {new Date().getFullYear()}</a>
          </Link>
        </div>
      </div>
    </StyledFooter>
  );
};

export default Footer;
