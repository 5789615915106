import { motion } from 'framer-motion';
import styled, { css } from 'styled-components';

export const RoundButton = styled.a`
  width: 164px;
  ${(props) =>
    props.large &&
    css`
      width: 300px;
    `}
  ${(props) =>
    props.medium &&
    css`
      width: 250px;
    `}
  height: 40px;
  ${(props) =>
    props.small &&
    css`
      width: 123px;
      height: 30px;
    `}
  border-radius: 50px;
  color: ${(props) => props.theme.primaryText};
  background: ${(props) => props.theme.primaryLink};
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 0.02em;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  justify-content: center;
  :hover {
    border: 1px solid ${(props) => props.theme.primaryText};
    color: ${(props) => props.theme.primaryText};
  }
`;

export const SuccessRoundButton = styled.a`
  width: 164px;
  ${(props) =>
    props.large &&
    css`
      width: 300px;
    `}
  ${(props) =>
    props.medium &&
    css`
      width: 250px;
    `}
  height: 40px;
  ${(props) =>
    props.small &&
    css`
      width: 123px;
      height: 30px;
    `}
  border-radius: 50px;
  color: ${(props) => props.theme.primaryText};
  background: ${(props) => props.theme.success};
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 0.02em;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  justify-content: center;
  :hover {
    border: 1px solid ${(props) => props.theme.primaryText};
    color: ${(props) => props.theme.primaryText};
  }
  &:disabled {
    background: ${({ theme }) => theme.greyBackground};
    color: ${({ theme }) => theme.headerBackground};
    border: none;
  }
`;

export const InvertedRoundButton = styled(motion.a)`
  width: 164px;
  font-family: 'Montserrat', sans-serif;
  ${(props) =>
    props.large &&
    css`
      width: 300px;
    `}
  ${(props) =>
    props.medium &&
    css`
      width: 250px;
    `}
  height: 40px;
  ${(props) =>
    props.small &&
    css`
      width: 123px;
      height: 30px;
    `}
  border-radius: 50px;
  background: ${(props) => props.theme.primaryText};
  color: ${(props) => props.theme.primaryLink};
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 0.02em;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  justify-content: center;
  ${(props) =>
    props.bannerButton &&
    css`
      min-width: 266px;
      height: 56px;
      font-size: 20px;
      line-height: 24px;
      text-transform: initial;
      width: auto;
      padding: 0 20px;
      @media (min-width: 320px) and (max-width: 550px) {
        font-size: 14px;
        line-height: 17px;
        min-width: 214px;
        height: 42px;
      }
    `}
  :hover {
    border: none;
    color: ${(props) => props.theme.primaryLink};
  }
`;

export const GreyRoundButton = styled(motion.a)`
  width: 164px;
  ${(props) =>
    props.large &&
    css`
      width: 300px;
    `}
  ${(props) =>
    props.medium &&
    css`
      width: 250px;
    `}
  height: 40px;
  ${(props) =>
    props.small &&
    css`
      width: 123px;
      height: 30px;
    `}
  border-radius: 50px;
  background: rgba(255, 255, 255, 0.4);
  box-shadow: 0px 6px 30px rgba(0, 0, 0, 0.15);
  color: ${(props) => props.theme.primaryText};
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 0.02em;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  justify-content: center;
  ${(props) =>
    props.bannerButton &&
    css`
      min-width: 266px;
      height: 56px;
      font-size: 20px;
      line-height: 24px;
      text-transform: initial;
      width: auto;
      padding: 0 20px;
      @media (min-width: 320px) and (max-width: 550px) {
        font-size: 14px;
        line-height: 17px;
        min-width: 214px;
        height: 42px;
      }
    `}
  :hover {
    border: none;
    color: ${(props) => props.theme.primaryText};
  }
`;

export const TransparentRoundButton = styled.a`
  width: 164px;
  ${(props) =>
    props.large &&
    css`
      width: 300px;
    `}
  ${(props) =>
    props.medium &&
    css`
      width: 266px;
    `}
  height: 40px;
  ${(props) =>
    props.small &&
    css`
      width: 123px;
      height: 30px;
    `}
  border-radius: 50px;
  background: transparent;
  color: ${(props) => props.theme.primaryText};
  border: 2px solid ${(props) => props.theme.primaryText};
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: 0.04em;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  justify-content: center;
  :hover {
    border: 2px solid ${(props) => props.theme.primaryText};
    color: ${(props) => props.theme.primaryText};
  }
`;

export const SquarePrimaryButton = styled.a`
  width: 220px;
  height: 45px;
  color: ${(props) => props.theme.primaryText};
  background: ${(props) => props.theme.primaryLink};
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.04em;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  justify-content: center;
  :hover {
    border: 1px solid ${(props) => props.theme.primaryText};
    color: ${(props) => props.theme.primaryText};
  }
`;

export const PrimarySubmitButton = styled.button`
  width: 164px;
  ${(props) =>
    props.large &&
    css`
      width: 300px;
    `}
  ${(props) =>
    props.medium &&
    css`
      width: 266px;
    `}
  height: 40px;
  ${(props) =>
    props.small &&
    css`
      width: 123px;
      height: 30px;
    `}
  border: none;
  border-radius: 50px;
  color: ${(props) => props.theme.primaryText};
  background: ${(props) => props.theme.primaryLink};
  font-weight: 600;
  font-size: 14px;
  cursor: pointer;
  line-height: 17px;
  letter-spacing: 0.02em;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  justify-content: center;
  :hover {
    border: 1px solid ${(props) => props.theme.primaryText};
    color: ${(props) => props.theme.primaryText};
  }
  &:disabled {
    background: ${({ theme }) => theme.greyBackground};
  }
`;

export const QuestionSubmitButton = styled.button`
  width: 164px;
  ${(props) =>
    props.large &&
    css`
      width: 300px;
    `}
  ${(props) =>
    props.medium &&
    css`
      width: 266px;
    `}
  height: 40px;
  ${(props) =>
    props.small &&
    css`
      width: 86px;
      height: 30px;
    `}
  border: none;
  border-radius: 3px;
  color: ${(props) => props.theme.primaryText};
  background: ${(props) => props.theme.primaryLink};
  font-weight: 600;
  font-size: 14px;
  cursor: pointer;
  line-height: 17px;
  letter-spacing: 0.02em;
  text-transform: capitalize;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const InvertedQuestionSubmitButton = styled.button`
  width: 164px;
  height: 40px;
  ${(props) =>
    props.large &&
    css`
      width: 300px;
    `}
  ${(props) =>
    props.medium &&
    css`
      width: 266px;
    `}
  ${(props) =>
    props.icon &&
    css`
      width: initial;
      height: initial;
    `}
  ${(props) =>
    props.small &&
    css`
      width: 86px;
      height: 30px;
    `}
  border: none;
  border-radius: 3px;
  background: ${(props) => props.theme.primaryText};
  color: ${(props) => props.theme.primaryLink};
  font-weight: 600;
  font-size: 14px;
  cursor: pointer;
  line-height: 17px;
  letter-spacing: 0.02em;
  text-transform: capitalize;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const TeamSubmitButton = styled.button`
  width: 164px;
  ${(props) =>
    props.large &&
    css`
      width: 300px;
    `}
  ${(props) =>
    props.medium &&
    css`
      width: 266px;
    `}
  height: 40px;
  ${(props) =>
    props.small &&
    css`
      width: 123px;
      height: 30px;
    `}
  border: none;
  border-radius: 50px;
  color: ${(props) => props.theme.primaryLink};
  background: ${(props) => props.theme.primaryText};
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 0.02em;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  justify-content: center;
  :hover {
    background: linear-gradient(
        269.96deg,
        ${(props) => props.theme.primaryLink} 0.04%,
        ${(props) => props.theme.headerBackground} 103.98%
      ),
      ${(props) => props.theme.primaryText};
    border: 2px solid ${(props) => props.theme.borderTeamBtnColor};
    color: ${(props) => props.theme.primaryText};
    font-family: Montserrat;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 20px;
    /* or 167% */

    letter-spacing: 0.02em;

    color: #ffffff;
  }
`;

export const LinkButton = styled.a`
  width: 164px;
  ${(props) =>
    props.large &&
    css`
      width: 300px;
    `}
  ${(props) =>
    props.medium &&
    css`
      width: 250px;
    `}
  height: 40px;
  ${(props) =>
    props.small &&
    css`
      width: 123px;
      height: 30px;
    `}
  background: transparent;
  color: ${(props) => props.theme.primaryLink};
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 0.02em;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const MiniSuccessButton = styled.button`
  height: 36px;
  border-radius: 25px;
  color: ${({ theme }) => theme.primaryText};
  background-color: ${({ theme }) => theme.borderGreen};
  font-size: 16px;
  line-height: 20px;
`;

export const MiniButton = styled.button`
  height: 36px;
  border-radius: 8px;
  color: ${({ theme }) => theme.headerBackground};
  background: ${({ theme }) => theme.primaryText};
  border: 1px solid rgba(26, 32, 43, 0.2);
  box-shadow: 1px 2px 4px rgba(0, 0, 0, 0.1);
  border-radius: 25px;
  font-size: 16px;
  line-height: 25px;
  padding: 0 24px;
  &.success-btn {
    color: ${({ theme }) => theme.primaryText};
    background-color: ${({ theme }) => theme.borderGreen};
    border: none;
    box-shadow: initial;
  }
`;
