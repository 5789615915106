const seo = {
  defaultTitle: 'Brytahub - Learn Vocational Skills Online',
  titleTemplate: '%s | Brytahub - Learn Vocational Skills Online',
  locale: 'en',
  url: 'https://brytahub.com/',
  site_name: 'Brytahub',
  description:
    'Learn vocational skills from industry experts with our indepth courses and extensive skill paths.',
  canonical: 'https://brytahub.com/',
  openGraph: {
    url: 'https://brytahub.com/',
    title: 'Brytahub - Learn Vocational Skills Online',
    type: 'website',
    locale: 'en',
    description:
      'Learn vocational skills from industry experts with our indepth courses and extensive learning paths.',
    images: [
      {
        url: 'https://www.brytahub.com/images/brytahub-logo-large.png',
        width: 2165,
        height: 663,
        alt: 'Brytahub - Learn Vocational Skills Online'
      }
    ],
    site_name: 'Brytahub'
  },
  twitter: {
    handle: '@brytahub',
    site: '@brytahub',
    cardType: 'summary_large_image'
  },
  additionalMetaTags: [
    {
      name: 'viewport',
      content:
        'user-scalable=no,initial-scale=1,maximum-scale=1,minimum-scale=1,width=device-width,height=device-height'
    }
  ],
  additionalLinkTags: [
    {
      rel: 'shortcut icon',
      href: 'https://brytahub.com/images/favicon.png'
    }
  ]
};

export default seo;
