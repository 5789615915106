import Link from 'next/link';
import { useRouter } from 'next/router';
import styled from 'styled-components';

const StyledTeamInvitationNotice = styled.div`
  background-color: ${(props) => props.theme.fadedGrey};
  color: ${(props) => props.theme.black};
  display: block;
  width: 100%;
  text-align: center;
  padding: 0.3em;
  font-size: 1.25rem;

  p {
    padding: 0;
    margin: 0;
  }

  .link {
    background-color: ${(props) => props.theme.primaryLink};
    color: white;
    padding: 0.25em 0.7em;
    margin-left: 0.5em;
    border-radius: 3px;
  }
`;

export const TeamInvitationNotice = ({ auth }) => {
  const router = useRouter();

  if (router.pathname.includes('/team/join') || !auth?.teamInvitation) {
    return null;
  }

  return (
    <StyledTeamInvitationNotice>
      <p>
        You have a team invitation.{' '}
        <Link href={`/team/join?invite=${auth.teamInvitation._id}`}>
          <a className="link">View</a>
        </Link>
      </p>
    </StyledTeamInvitationNotice>
  );
};
