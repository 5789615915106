import { GraphQLClient } from 'graphql-request';
async function main(mutation, variables, cookies) {
  let token = '';
  if (cookies && cookies.token) {
    token = cookies.token;
  }
  try {
    const graphQLClient = new GraphQLClient(process.env.NEXT_PUBLIC_API_ENDPOINT, {
      headers: {
        authorization: token ? token : ''
      }
    });

    const data = await graphQLClient.request(mutation, variables);
    return data;
  } catch (error) {
    let errors;
    if (error && error.response && error.response.errors && Array.isArray(error.response.errors)) {
      errors = error.response.errors;
    }
    if (errors) {
      errors.map((err) => {
        throw err.message.error?.message || err.message;
      });
    }
    return;
  }
}

export default main;
