import 'antd/dist/antd.min.css';
import '@brainhubeu/react-carousel/lib/style.css';
import 'toastr/build/toastr.min.css';
import 'public/css/nprogress.css';

import Head from 'next/head';
import Router from 'next/router';
import Script from 'next/script';
import { DefaultSeo } from 'next-seo';
import NProgress from 'nprogress';

import Page from '../components/shared/Page';
import SEO from '../lib/seo.config';

Router.events.on('routeChangeStart', () => NProgress.start());
Router.events.on('routeChangeComplete', () => {
  global.analytics.page();
  return NProgress.done();
});
Router.events.on('routeChangeError', () => NProgress.done());

export default function App(props) {
  const { Component, pageProps } = props;
  return (
    <>
      <DefaultSeo {...SEO} />
      <Head>
        <meta charSet="utf-8" />
        <link rel="preconnect" href="https://fonts.gstatic.com" crossOrigin />
        <link rel="preconnect" href="https://res.cloudinary.com" crossOrigin />
        <link rel="preconnect" href="https://api.brytahub.com" crossOrigin />

        {pageProps.ieBrowser && (
          <Script src="https://cdnjs.cloudflare.com/ajax/libs/babel-polyfill/7.2.5/polyfill.min.js" />
        )}
      </Head>
      <>
        <Page>
          <Component {...pageProps} />
        </Page>
      </>
    </>
  );
}
