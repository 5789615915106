import Image from 'next/image';
import loaderIcon from 'public/images/loader.gif';
import { useEffect, useState } from 'react';
import styled from 'styled-components';

const LoaderContainer = styled.div`
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  img {
    width: 100px;
    height: 100px;
    margin-bottom: 30px;
  }
  .quote-author {
    text-align: center;
    color: #ac005d;
  }
  .quote-text {
    font-size: 1.5rem;
    font-family: 'DM Serif Display', serif;
    text-align: center;
    color: #1a202b;
  }
  .quote-container {
    padding: 20px;
    font-size: 1.2rem;
    font-style: italic;
    font-family: 'Montserrat', sans-serif;
  }
`;

const quotes = [
  {
    text: 'Everyone is ordinary until trained.',
    author: 'Olusola Areogun'
  },
  {
    text: 'Give a man a fish and you feed him for a day. Teach him how to fish and you feed him for a lifetime.',
    author: 'Unknown'
  },
  {
    text: "Schooling doesn't assure employment but skill does.",
    author: 'Amit Kalantri'
  },
  {
    text: 'Build your skills, not your resume.',
    author: 'Sheryl Sandberg'
  },
  {
    text: 'All skills are learnable.',
    author: 'Brian Tracy'
  },
  {
    text: 'Skills make you rich, not theories.',
    author: 'Robert Kiyosaki'
  },
  {
    text: 'If you are working or you are running a business you have to set aside time and money to invest in your continued formal education and skills acquisition.',
    author: 'Strive Masiyiwa'
  }
];

export default function LoaderPage() {
  const [currentQuote, setCurrentQuote] = useState(null);
  useEffect(() => {
    const intervalId = setInterval(() => {
      const randomQuote = Math.floor(Math.random() * quotes.length);
      setCurrentQuote(quotes[randomQuote]);
    }, 5000);

    return () => clearInterval(intervalId);
  }, []);
  return (
    <LoaderContainer>
      <Image src={loaderIcon} width="100" height="100" alt="Loading..." />
      {currentQuote && (
        <div className="quote-container">
          <div className="quote-text">{currentQuote.text}</div>
          <div className="quote-author"> - {currentQuote.author}</div>
        </div>
      )}
    </LoaderContainer>
  );
}
