import { gql, GraphQLClient } from 'graphql-request';
import Router, { useRouter } from 'next/router';
import React, { useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import styled, { createGlobalStyle, ThemeProvider } from 'styled-components';
import tawkTo from 'tawkto-react';

import LoaderPage from '@/design-system/loader-page';

import Footer from './Footer';
import Header from './Header';

const theme = {
  headerBackground: '#1A202B',
  secondaryBackground: '#1A202B',
  primaryText: '#FFFFFF',
  cardBackground: '#212121',
  activeCardBackground: '#373737',
  grey: '#949494',
  greyBackground: '#E5E5E5',
  greyInput: '#2D2929',
  greyPlaceholder: '#2D2929',
  black: '#000000',
  primaryLink: '#AC005D',
  anotherText: '#2D2929',
  menuBlack: 'rgba(255,2555,255,.05)',
  menuHoverBlack: 'rgba(255,2555,255,.15)',
  lineGrey: '#585858',
  ash: '#949494',
  lightGrey: '#333333',
  lighterGrey: '#303030',
  newBg: '#1b212c',
  oddGrey: '#dedede',
  evenGrey: '#ebebeb',
  searchCard: '#222222',
  filterSubMenuGrey: '#4b4b4b',
  switchDisabledGrey: '#373737',
  dividerBackground: '#585858',
  authBg: '#2d282f',
  inputBg: '#FBF8F8',
  red: '#FF0000',
  success: '#1e9e14',
  fadedGrey: '#E5D6DF',
  borderGreen: '#1F9E14',
  borderTeamBtnColor: '#F2F2F2',
  flutterWave: '#F5A623',
  billingDesc: '#DFC4D3',
  teamAsh: '#43444F',
  upgradeCon: '#C4C4C4',
  sliderActive: '#ED2D63',
  sliderActive2: '#EC268D',
  testimonialBg: '#262F40',
  menuBg: '#2c2c2c',
  subMenuHoverBg: '#313131'
};

const StyledPage = styled.div`
  background: ${(props) => props.theme.newBg};
  color: ${(props) => props.theme.primaryText};
`;

const Inner = styled.div`
  max-width: 100%;
  margin: 0 auto;
  /* padding: 0 40px; */
  margin-top: 80px;
`;

const AuthPage = styled.div`
  max-width: 100%;
  margin: 0 auto;
  height: 100%;
`;

const GlobalStyle = createGlobalStyle`
@font-face {
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-display: fallback;
}
html {
    box-sizing: border-box;
    font-size: 10px;
}
*, *:before, *:after {
    box-sizing: inherit;
}
body {
    padding: 0;
    margin: 0;
    font-size: 1.5rem;
    line-height: 2;
    font-family: 'Montserrat', sans-serif !important;
    background-color: ${(props) => props.theme.headerBackground};
}
a {
    text-decoration: none;
}
button {
  border: none;
  cursor: pointer;
}
.ant-dropdown.ant-dropdown-placement-bottomLeft {
  width: 285px;
  left: 20% !important;
  top: 80px !important;
  position: fixed;
  background-color: ${(props) => props.theme.cardBackground};
  padding: 8px;
  border-radius: 6px;
}

.buy-modal {
  .ant-modal-close {
    display: none;
  }
  .ant-modal-body {
    padding: 0;
    display: grid;
    grid-template-columns: 1fr 2fr;
    @media (min-width: 760px) and (max-width: 1025px) {
      //tablet
      grid-template-columns: none;
      grid-template-rows: 2fr 3fr;
    }
    @media (max-width: 759px) {
      //phone
    grid-template-columns: none;
    grid-template-rows: 2fr 3fr;
    }
  }
  .ant-modal {
    width: 1098px !important;
    max-width: 100%;
    height: 548px;
    background: ${(props) => props.theme.headerBackground};

    @media (min-width: 760px) and (max-width: 1025px) {
      //tablet
      width: 684px !important;
      height: 818px;
    }
    @media (max-width: 759px) {
      //phone
      width: 382px !important;
      max-width: 100%;
      height: 682px;
    }
  }
  .ant-modal-content {
    background: ${(props) => props.theme.headerBackground};
    box-shadow: none;
    width: 1098px;
    max-width: 100%;
    height: 548px;
    padding: 0;
    display: grid;
    @media (min-width: 760px) and (max-width: 1025px) {
      //tablet
      width: 684px !important;
      height: 818px;
    }
    @media (max-width: 759px) {
      //phone
      width: 382px !important;
      max-width: 100%;
      height: 682px;
    }
  }
  .buy-pic {
    background-image: url("/images/buy-picture.jpg");
    background-size: cover;
    padding: 48px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
      @media (min-width: 760px) and (max-width: 1025px) {
        //tablet
        background-image: url("/images/buy-picture-tab.jpg");
        background-size: cover;
      }
    @media (max-width: 759px) {
      //phone
    padding: 16px;
    background-image: url("/images/buy-picture-tab.jpg");
    background-size: cover;
    }
    .buy-pic-title {
      font-family: "DM Serif Display", sans-serif;
      font-style: normal;
      font-weight: 900;
      font-size: 32px;
      line-height: 38px;
      letter-spacing: 0.05em;
      max-width: 253px;
      color: ${(props) => props.theme.primaryText};
      @media (min-width: 760px) and (max-width: 1025px) {
        //tablet
        width: 500px !important;
        max-width: 500px;
        margin: 0 auto;
        text-align: center;
      }
      @media (max-width: 759px) {
        //phone
        font-size: 24px;
        line-height: 28px;
        width: 344px !important;
        max-width: 100%;
        text-align: center;
        margin: 0 auto;
      }
    }
    .buy-pic-desc {
      max-width: 387px;
      font-style: normal;
      font-weight: normal;
      font-size: 18px;
      line-height: 30px;
      letter-spacing: 0.02em;
      color: ${(props) => props.theme.fadedGrey};
      margin-top: 18px;
      @media (min-width: 760px) and (max-width: 1025px) {
        //tablet
        width: 500px !important;
        max-width: 500px;
        margin: 0 auto;
        text-align: center;
      }
      @media (max-width: 759px) {
        //phone
        font-size: 16px;
        line-height: 30px;
        width: 344px !important;
        max-width: 100%;
        margin: 0 auto;
      }
    }
    .buy-nav {
      display: flex;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 20px;
      letter-spacing: 0.02em;
      text-transform: lowercase;
      color: ${(props) => props.theme.primaryText};
      button {
        background: transparent;
      }
    }
  }
  .buy-coupon-area {
    margin-bottom: 1em;
  }

  .buy-actions {
    margin-top: 49px;
    @media (min-width: 760px) and (max-width: 1025px) {
      //tablet
      margin-top: 48px;
      display: grid;
      justify-content: center;
    }
    @media (max-width: 759px) {
      //phone
      margin-top: 40px;
    }
  }
  .buy-actions > a {
    margin-bottom: 32px;
    @media (min-width: 760px) and (max-width: 1025px) {
      //tablet
      margin-bottom: 0;
    }
    @media (max-width: 759px) {
      //phone
      margin-bottom: 0;
    }
  }

  .buy-text {
    padding: 43px 150px;
      @media (max-width: 759px) {
        //phone
        padding: 32px 28px;
      }
    .buy-price {
      font-style: normal;
      font-weight: normal;
      margin-top: 40px;
      font-size: 56px;
      line-height: 50px;
      letter-spacing: 0.06em;
      color: ${(props) => props.theme.primaryText};
      @media (min-width: 760px) and (max-width: 1025px) {
        //tablet
        display: flex;
        justify-content: center;
      }
      @media (max-width: 759px) {
        //phone
        margin-top: 32px;
        font-size: 40px;
        line-height: 50px;
        display: flex;
        justify-content: center;
      }
    }
    .buy-price-text-title {
      margin-top: 32px;
      font-style: normal;
      font-weight: normal;
      font-size: 18px;
      line-height: 22px;
      letter-spacing: 0.02em;
      color: ${(props) => props.theme.fadedGrey};
      @media (max-width: 759px) {
        //phone
        font-size: 16px;
        margin-top: 16px;
      }
    }
    .buy-price-text-desc {
      margin-top: 12px;
      font-style: normal;
      font-weight: normal;
      font-size: 18px;
      line-height: 22px;
      letter-spacing: 0.02em;
      color: ${(props) => props.theme.primaryText};
      max-width: 383px;
      ul > li {
        margin-top: 16px;
      }
      @media (max-width: 759px) {
        //phone
        font-size: 16px;
      }
    }
    .pricing-switch {
      @media (min-width: 760px) and (max-width: 1025px) {
        //tablet
        display: flex;
        justify-content: center;
      }
      @media (max-width: 759px) {
        //phone
        display: flex;
        justify-content: center;
      }
      .switch-light.switch-candy {
        height: 32px;
        width: 169px;
        .plan1,
        .plan2 {
          height: 32px;
          width: 84px;
          display: grid;
          place-items: center;
          font-size: 18px;
          line-height: 22px;
          letter-spacing: 0.02em;
          text-shadow: none;
          font-weight: normal;
        }
        .plans-box {
          border-radius: 32px;
          background: ${(props) => props.theme.primaryText};
          box-shadow: 0px 3px 10.5px -1px rgba(172, 0, 93, 0.25);
          .active {
            color: ${(props) => props.theme.primaryText};
          }
          .inactive {
            color: ${(props) => props.theme.cardBackground};
            opacity: 0.8;
          }
        }
      }
    }
  }
}

.team-member-removal-modal {
  .ant-modal-close {
  }
  .ant-modal-close-icon {
    color: ${(props) => props.theme.cardBackground};
    background: ${(props) => props.theme.primaryText};
    font-size: 24px;
    font-weight: bold;
  }
  .ant-modal-body {
    padding: 0;
  }
  .ant-modal {
    max-width: 1000px !important;
    min-width: 767px;
    width: 100%;
    background: ${(props) => props.theme.headerBackground};
  }
  .ant-modal-content {
    background: ${(props) => props.theme.headerBackground};
    box-shadow: none;
    width: 100%;
    max-width: 100%;
    height: 100%;
    padding: 0;
  }
}

.unlock-modal {
    .ant-modal-close {
    }
    .ant-modal-close-icon {
      color: ${(props) => props.theme.cardBackground};
      background: ${(props) => props.theme.primaryText};
      font-size: 24px;
      font-weight: bold;
    }
    .ant-modal-body {
      padding: 0;
      display: grid;
      grid-template-columns: 1fr 2fr;
      @media (max-width: 759px) {
        //phone
      grid-template-columns: none;
      grid-template-rows: 1fr 2fr;
      }
    }
    .ant-modal {
      width: 1000px !important;
      max-width: 100%;
      height: 420px;
      background: ${(props) => props.theme.headerBackground};

      @media (min-width: 760px) and (max-width: 1025px) {
        //tablet
        width: 730px !important;
        height: 420px;
      }
      @media (max-width: 759px) {
        //phone
        width: 684px !important;
        height: calc(100vh - 80px);
        top: 72px;
      }
    }
    .ant-modal-content {
      background: ${(props) => props.theme.headerBackground};
      box-shadow: none;
      width: 1000px;
      max-width: 100%;
      height: 420px;
      padding: 0;
      display: grid;
      @media (min-width: 760px) and (max-width: 1025px) {
        //tablet
        width: 730px !important;
        height: 420px;
      }
      @media (max-width: 759px) {
        //phone
        width: 684px !important;
        height: calc(100vh - 80px);
        padding: 0;
      }
    }
    // .unlock-pic {
      
    //   background-size: cover;
    //   @media (max-width: 759px) {
    //     //phone
    //   background-image: url("/images/unlock-picture-mobile.jpg");
    //   background-size: cover;
    //   }
    // }
    .unlock-text {
      padding: 74px 128px;
      font-style: normal;
      font-weight: normal;
      font-size: 24px;
      line-height: 32px;
      letter-spacing: 0.02em;
      color: ${(props) => props.theme.primaryText};
      @media (min-width: 760px) and (max-width: 1025px) {
        //tablet
      padding: 74px 88px;
      font-size: 18px;
      line-height: 28px;
      }
      @media (max-width: 759px) {
        //phone
      padding: 74px 54px;
      font-size: 18px;
      line-height: 28px;
      }
    }
    .unlock-actions {
      margin-top: 54px;
      display: flex;
      flex-direction: column;
      @media (min-width: 760px) and (max-width: 1025px) {
        //tablet
      margin-top: 66px;
      }
      @media (max-width: 759px) {
        //phone
      margin-top: 66px;
      }
    }
    .unlock-actions > a {
      margin-bottom: 32px;
    }
  }


.checkout-modal {
  .ant-modal-close {
    display: none;
  }
  .ant-modal-body {
    padding: 0;
  }
  .ant-modal {
    width: 100% !important;
    max-width: 1028px !important;
    background: ${(props) => props.theme.headerBackground};
  }
  .ant-modal-content {
    background: ${(props) => props.theme.headerBackground};
    box-shadow: none;
    max-width: 100%;
    padding: 32px;
    color: ${(props) => props.theme.primaryText};
  }
}

.payment-container {
  .ant-modal-close {
    display: none;
  }
  .ant-modal-body {
    padding: 0;
  }
  .ant-modal {
    width: 1098px !important;
    max-width: 100%;
    height: 548px;
    background: ${(props) => props.theme.headerBackground};

    @media (min-width: 760px) and (max-width: 1025px) {
      //tablet
        width: 684px !important;
        height: 655px;
    }
    @media (max-width: 759px) {
      //phone
        width: 684px !important;
        height: calc(100vh - 80px);
        top: 72px;
    }
  }
  .ant-modal-content {
    background: ${(props) => props.theme.headerBackground};
    box-shadow: none;
    width: 1098px;
    max-width: 100%;
    height: 548px;
    padding: 48px;

    @media (min-width: 760px) and (max-width: 1025px) {
      //tablet
        width: 684px !important;
        height: 655px;
    }
    @media (max-width: 759px) {
      //phone
        width: 684px !important;
        height: calc(100vh - 80px);
        padding: 32px 16px;
    }
  }
  .payment-title-section {
    display: grid;
    grid-template-columns: 4fr 5fr;
    @media (max-width: 759px) {
      //phone
      grid-template-columns: 2fr 5fr;
    }
  }
  .payment-nav {
    background: transparent;
    cursor: pointer;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0.02em;
    text-transform: lowercase;
    color: ${(props) => props.theme.fadedGrey};
    justify-self: start;
  }
  .payment-title {
    font-size: 24px;
    line-height: 40px;
    text-align: center;
    letter-spacing: 0.02em;
    color: ${(props) => props.theme.fadedGrey};
    justify-self: start;

    @media (min-width: 760px) and (max-width: 1025px) {
      //tablet
        font-size: 24px;
        line-height: 32px;
    }
    @media (max-width: 759px) {
      //phone
        font-size: 16px;
        line-height: 20px;
    }
  }
  .payment-price {
    text-align: center;
    margin-top: 70px;
    font-style: normal;
    font-weight: normal;
    font-size: 56px;
    line-height: 40px;
    letter-spacing: 0.02em;
    color: ${(props) => props.theme.primaryText};
    @media (max-width: 759px) {
      //phone
        font-size: 40px;
        line-height: 40px;
    }
  }
  .payment-notice {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 28px;
    text-align: center;
    letter-spacing: 0.02em;
    color: ${(props) => props.theme.fadedGrey};
    margin: 16px auto 43px auto;
    max-width: 505px;
    text-align: center;
    @media (min-width: 760px) and (max-width: 1025px) {
      //tablet
    max-width: 100%;
    }
    @media (max-width: 759px) {
      //phone
    margin: 13px auto 39px auto;
    line-height: 22px;
    }
  }
  .payment-gateway-section {
    display: flex;
    // max-width: 928px;
    width: 100%;
    margin: 0 auto;
    align-items: center;
    @media (min-width: 760px) and (max-width: 1025px) {
      //tablet
      flex-direction: column;
      width: 504px;
      max-width: 100%;
    }
    @media (max-width: 759px) {
      //phone
      flex-direction: column;
      width: 504px;
      max-width: 100%;
    }
  }
  .payment-gateway {
    width: 416px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: ${(props) => props.theme.primaryText};
    font-size: 18px;
    border-radius: 5px;
    padding: 0.75em 2em;

    img {
      width: 30px;
    }

    span {
      margin-left: 0.5em;
      font-weight: bold;
    }

    @media (min-width: 760px) and (max-width: 1025px) {
      //tablet
      width: 504px;
      max-width: 100%;
    }
    @media (max-width: 759px) {
      //phone
      width: 379px;
      max-width: 100%;
    }
  }
  .flutterwave {
    color: ${(props) => props.theme.flutterWave};
  }
  .paystack {
    color: ${(props) => props.theme.cardBackground};
  }
  .payment-footer {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    opacity: 0.8;
    line-height: 28px;
    text-align: center;
    letter-spacing: 0.02em;
    color: ${(props) => props.theme.primaryText};
    width: 488px;
    margin: 18px auto 0 auto;
    @media (max-width: 759px) {
      //phone
      width: 248px;
      max-width: 100%;
    }
  }
  .payment-gateway-divider {
    margin: 0 32px;
    font-family: Montserrat;
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    line-height: 49px;
    text-align: center;
    letter-spacing: 0.02em;
    color: ${(props) => props.theme.primaryText};
    @media (max-width: 759px) {
      //phone
      font-size: 18px;
    }
  }
}


.add-team-modal {
  .ant-modal-close {
    display: none;
  }
  .ant-modal-body {
    padding: 0;
  }
  .ant-modal {
    width: 706px !important;
    max-width: 100%;
    height: 548px;
    background: ${(props) => props.theme.headerBackground};

    @media (min-width: 760px) and (max-width: 1025px) {
      //tablet
        width: 684px !important;
        height: 655px;
    }
    @media (max-width: 759px) {
      //phone
        width: 684px !important;
        height: calc(100vh - 80px);
        top: 72px;
    }
  }
  .ant-modal-content {
    background: ${(props) => props.theme.headerBackground};
    box-shadow: none;
    width: 1098px;
    max-width: 100%;
    height: 548px;
    padding: 56px 32px;

    @media (min-width: 760px) and (max-width: 1025px) {
      //tablet
        width: 684px !important;
        height: 655px;
    }
    @media (max-width: 759px) {
      //phone
        width: 684px !important;
        height: calc(100vh - 80px);
        padding: 32px 16px;
    }
  }
  .add-team-nav {
    background: transparent;
    cursor: pointer;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0.02em;
    color: ${(props) => props.theme.fadedGrey};
    justify-self: start;
  }
  .add-team-title {
    font-size: 20px;
    line-height: 40px;
    font-weight: 600;
    text-align: center;
    letter-spacing: 0.02em;
    color: ${(props) => props.theme.fadedGrey};
    justify-self: start;
    margin-top: 20px;
    margin-bottom: 24px;
    padding: 0 13px;

    @media (min-width: 760px) and (max-width: 1025px) {
      //tablet
        font-size: 24px;
        line-height: 32px;
    }
    @media (max-width: 759px) {
      //phone
        font-size: 16px;
        line-height: 20px;
    }
  }
  input {
    height: 56px !important;
    margin: 0 50px;
    width: calc(100% - 100px) !important;
    background: ${(props) => props.theme.fadedGrey} !important;
    font-weight: 600;
    font-size: 20px;
    line-height: 40px;
    border-radius: 12px;
  }
  .invite-notify {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 24px;
    color: ${(props) => props.theme.fadedGrey};
    margin-top: 16px;
    text-align: center;
  }
  .upgrade-title {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 32px;
    color: ${(props) => props.theme.primaryText};
    margin-top: 20px;
    text-align: center;
  }
  .upgrade-container {
    margin: 20px auto;
    height: 72px;
    width: 184px;
    border-radius: 84px;
    background-color: ${(props) => props.theme.upgradeCon};
    padding: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .upgrade-button {
    height: 48px;
    width: 48px;
    border-radius: 50%;
    background: linear-gradient(269.96deg, ${(props) => props.theme.primaryLink} 0.04%, ${(props) =>
  props.theme.headerBackground} 103.98%);
    border: none;
    color: ${(props) => props.theme.upgradeCon};
    font-size: 30px;
    font-weight: 900;
  }
  .upgrade-value {
    width: 64px;
    height: 64px;
    border-radius: 50%;
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 40px;
    color: ${(props) => props.theme.primaryText};
    display: grid;
    place-items: center;
    background: ${(props) => props.theme.teamAsh}
  }
  .upgrade-action {
    display: grid;
    place-items: center;
  }
}



.demo-success-modal {
  .ant-modal-close {
    color: ${(props) => props.theme.primaryText};
  }
    .ant-modal-content {
      width: 766px;
      height: 508px;
      background: ${(props) => props.theme.headerBackground};
      border: 1px solid ${(props) => props.theme.borderGreen};
      box-sizing: border-box;
      box-shadow: 5px 5px 20px 1px rgba(0, 0, 0, 0.2);

    @media (min-width: 760px) and (max-width: 1025px) {
      //tablet
      height: 540px;
      width: 766px;
      left: -100px;
    }
    @media (max-width: 759px) {
      //phone
      height: 504px;
      width: 366px;
      max-width: 100%;
    }
    }
    .title {
      font-family: "DM Serif Display", sans-serif;
      font-style: normal;
      font-weight: 900;
      font-size: 32px;
      line-height: 44px;
      text-align: center;
      letter-spacing: 0.03em;
      color: ${(props) => props.theme.primaryText};
      opacity: 0.8;
      margin: 56px auto 32px auto;
    @media (max-width: 759px) {
      //phone
      margin: 48px auto 24px auto;
    }
    }
    .body {
      font-family: "Montserrat", sans-serif;
      font-style: normal;
      font-weight: normal;
      font-size: 24px;
      line-height: 38px;
      max-width: 560px;
      text-align: center;
      letter-spacing: 0.02em;
      color: ${(props) => props.theme.primaryText};
      margin: 32px auto;
    @media (max-width: 759px) {
      //phone
      margin: 24px auto;
      font-size: 16px;
      line-height: 32px;
    }
    }
    .footer {
      font-family: "Montserrat", sans-serif;
      font-style: normal;
      font-weight: normal;
      font-size: 24px;
      line-height: 38px;
      text-align: center;
      letter-spacing: 0.02em;
      max-width: 560px;
      margin: 34px auto 0 auto;
      display: flex;
      justify-content: center;
      color: ${(props) => props.theme.primaryText};
    @media (max-width: 759px) {
      //phone
      margin: 32px auto;
      font-size: 18px;
      line-height: 38px;
      align-items: center;
      display: grid;
      grid-gap: 24px;
    }
    }
  }
  /* For components/dashboard/content */
  .ant-dropdown.content-card-menu.ant-dropdown-placement-bottomCenter {
    background-color: ${(props) => props.theme.borderTeamBtnColor};
    width: 112px;
    ul.ant-dropdown-menu.ant-dropdown-menu-light.ant-dropdown-menu-root.ant-dropdown-menu-vertical {
      background-color: ${(props) => props.theme.borderTeamBtnColor};
    }
    li.ant-dropdown-menu-item.content-card-month.ant-dropdown-menu-item-only-child {
      color: ${(props) => props.theme.cardBackground};
    }
    li.ant-dropdown-menu-item.content-card-month.ant-dropdown-menu-item-only-child.ant-dropdown-menu-item-active,
    li.ant-dropdown-menu-item.content-card-month.ant-dropdown-menu-item-only-child.content-card-active-month {
      background-color: ${(props) => props.theme.fadedGrey};
    }
  }

  /* For component dashboard/team/header */
  a.team-dashboard-header-mobile-menu {
    font-size: 16px;
    line-height: 20px;
    color: ${(props) => props.theme.cardBackground}
  }

  /* For components/shared/Header submenu */
  .ant-menu-submenu.ant-menu-submenu-popup.ant-menu-light.ant-menu-submenu-placement-rightTop:not(.ant-menu-submenu-hidden) {
    background-color: ${(props) => props.theme.cardBackground};
    padding: 8px;
    border-radius: 6px;
  }
  ul.ant-menu.ant-menu-sub.ant-menu-vertical {
    background: ${(props) => props.theme.cardBackground};
  }
  li.ant-menu-item.ant-menu-item-only-child,
  li.ant-menu-item.ant-menu-item-only-child a,
  li.ant-menu-item.ant-menu-item-only-child a:hover {
    color: ${(props) => props.theme.fadedGrey};
    background-color: ${(props) => props.theme.menuBg} !important;
    margin-bottom: 1px !important;
    border-radius: 2px;
  }
  li.ant-menu-item.ant-menu-item-only-child:hover,
  li.ant-menu-item.ant-menu-item-only-child a:hover {
    background-color: ${(props) => props.theme.subMenuHoverBg} !important;
  }

  .ant-select-dropdown.ant-select-dropdown-placement-bottomLeft {
    background-color: ${(props) => props.theme.testimonialBg} !important;
    padding: 8px;
    border-radius: 8px;
  }
  .ant-select-item.ant-select-item-option {
    background: rgba(196, 196, 196, 0.5) !important;
    border-radius: 2px;
    margin-bottom: 1px;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: ${(props) => props.theme.primaryText};
    height: 32px;
  }
  .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
    height: 32px;
    background: rgba(196, 196, 196, 0.5) !important;
    border-radius: 2px;
    margin-bottom: 1px;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: ${(props) => props.theme.primaryText};
  }
  .ant-popover-inner-content {
    display: flex;
  }
`;

const getProfile = async (token) => {
  try {
    const graphQLClient = new GraphQLClient(process.env.NEXT_PUBLIC_API_ENDPOINT, {
      headers: {
        authorization: token ? token : ''
      }
    });

    const query = gql`
      {
        profile(input: { enrolledCourses: 8, bookmarks: 1, watchHistory: 8 }) {
          _id
          firstName
          lastName
          email
          imageUrl
          role
          credit
          referralCode
          coursesPaidFor {
            _id
            course_id
          }
          currentSubscription {
            subscription {
              _id
              plan_id
              is_active
              is_team
              userId
              is_payment_made
              is_subscription_canceled
              team {
                numAllocatedMemberSlots
                _id
                members {
                  _id
                }
              }
              payment_id
              expiry_date
              card {
                exp_year
                exp_month
                last4
                card_type
              }
              plan {
                name
                description
                interval
                amount
                currency {
                  name
                  symbol
                }
              }
            }
          }
          watchHistory {
            _id
          }
          enrolledCourses {
            _id
          }
          teamInvitation {
            _id
            teamId
            canceledAt
            creatorId
            acceptedAt
            declinedAt
            expiryDate
            createdAt
            creator {
              firstName
              lastName
            }
          }
        }
      }
    `;

    const { profile } = await graphQLClient.request(query);

    return profile;
  } catch (error) {
    // console.error(error.response.errors[0].message.message);
  }
};

const Page = (props) => {
  const router = useRouter();
  const [cookies, setCookie] = useCookies([]);
  const { token } = cookies;

  const [menuOpen, setMenuOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [pathName, setPathName] = useState('');
  const [auth, setAuth] = useState({});
  //const [activeSubscription, setActiveSubscription] = useState(null);

  const authPaths = [
    '/signup',
    '/login',
    '/register',
    '/signin',
    '/forgot-password',
    '/reset-password'
  ];

  let path = null;
  if (typeof window !== 'undefined') {
    path = Router.pathname;
  }

  useEffect(() => {
    const getUser = async () => {
      setIsLoading(true);
      const user = await getProfile(token);
      setAuth(user);
      setCookie('loggedInUser', user, { path: '/' });
      setIsLoading(false);
    };
    getUser();
    setPathName(path);
    console.log('changed');
  }, [router.pathname, cookies]);

  useEffect(() => {
    if (process.env.NEXT_PUBLIC_TAWK_TO_PROPERTY_ID && process.env.NEXT_PUBLIC_TAWK_TO_KEY) {
      tawkTo(process.env.NEXT_PUBLIC_TAWK_TO_PROPERTY_ID, process.env.NEXT_PUBLIC_TAWK_TO_KEY);
    }
  }, []);

  const updateChildrenWithProps = React.cloneElement(props.children, {
    auth
  });

  if (isLoading) {
    return <LoaderPage />;
  }

  return (
    <ThemeProvider theme={theme}>
      {authPaths.includes(pathName) ? (
        <AuthPage>{props.children}</AuthPage>
      ) : (
        <StyledPage>
          <GlobalStyle />
          <Header menuOpen={menuOpen} setMenuOpen={setMenuOpen} auth={auth} />
          <Inner onClick={() => setMenuOpen(false)} auth={auth}>
            {updateChildrenWithProps}
          </Inner>
          <Footer auth={auth} onClick={() => setMenuOpen(false)} />
        </StyledPage>
      )}
    </ThemeProvider>
  );
};

export default Page;
